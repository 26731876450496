/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1xzvjke --style2 --full --parallax pb--60 pt--60" name={"acsie3u6y1b"} fullscreen={true} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/f39bdec08daf426293c08dc33058bd11_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/f39bdec08daf426293c08dc33058bd11_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/f39bdec08daf426293c08dc33058bd11_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/f39bdec08daf426293c08dc33058bd11_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/f39bdec08daf426293c08dc33058bd11_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/f39bdec08daf426293c08dc33058bd11_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/f39bdec08daf426293c08dc33058bd11_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/f39bdec08daf426293c08dc33058bd11_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--1 mb--50 flex--center" columns={"1"} fullscreen={false} anim={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--72 title-box--invert" style={{"maxWidth":650}} content={"<span style=\"color: var(--white)\"><span style=\"font-weight: bold;\">Váš KLÍČNÍK<br>specialista pro Vaše bezpečí</span><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--16 pt--16" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--center" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 --center pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"rgba(0,0,0,0.01)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/19567/489bb8c40ba849cb96d2735e5f70042a_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={"https://cdn.swbpg.com/t/19567/489bb8c40ba849cb96d2735e5f70042a_s=350x_.png 350w"} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Výroba klíčů<br><br>"}>
              </Subtitle>

              <Text className="text-box" content={"Na naší prodejně duplikujeme širokou škálu klíčů. Od obyčejných až po bezpečností a trezorové."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 --center pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"rgba(0,0,0,0.01)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/19567/48b21c7db7094bd3aa9d47a54f300436_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={"https://cdn.swbpg.com/t/19567/48b21c7db7094bd3aa9d47a54f300436_s=350x_.png 350w"} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Dveřní servis<br><br>"}>
              </Subtitle>

              <Text className="text-box" content={"Provádíme servis dveřních křídel. Seřizování, montáže dveřního příslušenství a doplňků."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 --center pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"rgba(0,0,0,0.01)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/19567/b57dabe5c89e4229aee6808bdfca0ee9_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={"https://cdn.swbpg.com/t/19567/b57dabe5c89e4229aee6808bdfca0ee9_s=350x_.png 350w"} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Systémy generálního klíče"}>
              </Subtitle>

              <Text className="text-box" content={"Konzultace, projektování a realizace systémů generálního klíče tzv. SGHK&nbsp;<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 --center pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"rgba(0,0,0,0.01)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/19567/114050ef72f944eb8185fd529539f8ab_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={"https://cdn.swbpg.com/t/19567/114050ef72f944eb8185fd529539f8ab_s=350x_.png 350w"} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Trezorový servis"}>
              </Subtitle>

              <Text className="text-box" content={"Montáže, servis a otevírání trezorů, bezpečnostních schránek a zbrojních skříní,<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 --center pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"rgba(0,0,0,0.01)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/19567/bc39ab8dfc8242428b5dbb7d4f3807c7_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={"https://cdn.swbpg.com/t/19567/bc39ab8dfc8242428b5dbb7d4f3807c7_s=350x_.png 350w"} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Výroba autoklíčů"}>
              </Subtitle>

              <Text className="text-box" content={"Mechanické kopírování, programování a výměna baterii autoklíčů. Servis spínacích skříní, auto zámků, tažných zařízení. Nouzové otevírání aut."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 --center pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"rgba(0,0,0,0.01)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/19567/2c846aba154c4ccead180a0bccac30f4_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={"https://cdn.swbpg.com/t/19567/2c846aba154c4ccead180a0bccac30f4_s=350x_.png 350w"} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Elektronické zámky"}>
              </Subtitle>

              <Text className="text-box" content={"Montáže elektromechanických a elektromotorických uzamykacích systémů.<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-14bc1xq css-42e4bw pb--20 pt--80" layout={"l1"} name={"pyzxlz68lmg"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --right el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Váš KLÍČNÍK</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: var(--white);\">Miroslav Košůtek\n<br>Náplatková 216/12, 747 23, Bolatice<br>IČ: 86670565<br>DIČ: CZ7302125435</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}